import React, { FC, LegacyRef, useContext, useEffect, useRef } from 'react'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import { LayoutContext } from '../../../contexts/layoutProvider'

import Burger from './Burger/Burger'
import MainNav from './MainNav/MainNav'
import Controls from './Controls/Controls'
import Logo from './Logo/Logo'
import SecondNav from './SecondNav/SecondNav'
import MaxWidth from '../MaxWidth/MaxWidth'
import MobileNavModal from '../../modals/MobileNavModal'
import SearchField from './SearchField/SearchField'
import MobileNav from './MobileNav/MobileNav'
import { CSSTransition } from 'react-transition-group'
import css from './Header.module.scss'
import cx from 'classnames'
import { HeaderColors, HeaderTextColors } from '../../types'
import AsideModal from '../../modals/AsideModal'
import Auth from '../../forms/Auth/Auth'
import { useRouter } from 'next/router'
import MobileSocials from './MobileSocials/MobileSocials'
import CartAsideTop from '../../checkout/cart/CartAsideTop'
import AsideBottom from '../../checkout/cart/AsideBottom'
import FreeDelivery from '../../checkout/cart/FreeDelivery'
import CartList from '../../checkout/cart/CartList'
import { CartContext } from '../../../contexts/cart'
import EmptyCart from '../../checkout/cart/EmptyCart'
import { AuthContext } from '../../../contexts/auth'
import Link from 'next/link'

const TOP_BORDER = 400

const Header: FC = () => {
  const { empty, checkoutModalOpen, setCheckoutModalOpen } =
    useContext(CartContext)
  const router = useRouter()
  const { query } = router
  const refHeader = useRef<HTMLElement>(null)
  const scrollPosition = useRef(0)
  const {
    mobileNavOpen,
    setSecondNavOpen,
    setMobileNavOpen,
    secondNavOpen,
    headerColor,
    headerInitColor,
    setHeaderColor,
    searchOpen,
    setSearchOpen,
    headerTextColor,
    setHeaderTextColor,
    fixed,
    setFixed,
    hideBorder,
  } = useContext(LayoutContext)

  const { authModalOpen, setAuthModalOpen } = useContext(AuthContext)

  useOnClickOutside(refHeader, () => {
    setSecondNavOpen(false)
    setHeaderColor(headerInitColor)
    setSearchOpen(false)
  })

  const active = secondNavOpen || mobileNavOpen

  useEffect(() => {
    if (!query?.change_password) {
      return
    }
    setAuthModalOpen(true)
  }, [query?.change_password])

  useEffect(() => {
    const fixedHandler = () => {
      const currentScroll = window.scrollY

      setFixed(
        currentScroll < scrollPosition.current && currentScroll > TOP_BORDER,
      )

      scrollPosition.current = window.scrollY
    }

    window.addEventListener('scroll', fixedHandler)

    return () => {
      window.removeEventListener('scroll', fixedHandler)
    }
  }, [])

  const desktopOpenHandler = () => {
    setSecondNavOpen(true)
    setMobileNavOpen(false)
    setHeaderColor(HeaderColors.light)
    setHeaderTextColor(HeaderTextColors.init)
    setSearchOpen(false)
  }

  const closeHandler = () => {
    setSecondNavOpen(false)
    setHeaderColor(headerInitColor)
    setSearchOpen(false)
    setMobileNavOpen(false)
  }

  const renderHeader = (ref?: LegacyRef<HTMLElement>) => {
    return (
      <header
        className={cx(
          css.container,
          mobileNavOpen && css.active,
          css[headerColor],
          css[`text-color-${headerTextColor}`],
          headerInitColor === 'fixedTransparent' && css.fixedTransparent,
        )}
        ref={ref || undefined}
        onPointerLeave={() => {
          if (!searchOpen) {
            setSecondNavOpen(false)
            setHeaderColor(headerInitColor)
          }
        }}
      >
        <MaxWidth>
          <div className={cx(css.wrapper, hideBorder && css.hideBorder)}>
            <Burger
              className={css.menu}
              onPointerEnter={desktopOpenHandler}
              onClick={desktopOpenHandler}
              active={active}
            />
            <Burger
              className={css.menu_mobile}
              onClick={() => {
                setSecondNavOpen(false)
                setMobileNavOpen((prev) => !prev)
              }}
              active={active}
            />
            <MainNav onClick={closeHandler} />
            <Logo onClick={closeHandler} />
            <div className={css.controlsWrapper}>
              <div className={css.linksWrapper}>
                <Link href="/catalog/detskaya_mineralnaya_kosmetika" passHref>
                  <a aria-label="для детей" className={css.kidsLink}>
                    для детей
                  </a>
                </Link>
                <Link href="/julirea" passHref>
                  <a
                    aria-label="julirea"
                    className={cx(
                      css.julireaLink,
                      headerColor === HeaderColors.black &&
                        css['julireaLink--black'],
                    )}
                  >
                    уходовая линейка
                  </a>
                </Link>
              </div>

              <Controls
                setAuthModalOpen={setAuthModalOpen}
                setCheckoutModalOpen={setCheckoutModalOpen}
                onClick={closeHandler}
                withJulirea
              />
            </div>
          </div>
        </MaxWidth>

        <SecondNav />
        <div className={css.search}>
          <SearchField />
        </div>

        <AsideModal
          open={authModalOpen}
          onClose={() => setAuthModalOpen(false)}
        >
          <Auth closeModal={() => setAuthModalOpen(false)} />
        </AsideModal>

        <AsideModal
          open={checkoutModalOpen}
          className={css.cartModal}
          top={<CartAsideTop onClose={() => setCheckoutModalOpen(false)} />}
          onClose={() => setCheckoutModalOpen(false)}
        >
          {empty ? (
            <EmptyCart className={css.empty} />
          ) : (
            <div className={css.content}>
              <FreeDelivery />
              <CartList closeHandler={() => setCheckoutModalOpen(false)} />
              <AsideBottom onClose={() => setCheckoutModalOpen(false)} />
            </div>
          )}
        </AsideModal>
      </header>
    )
  }

  return (
    <>
      <CSSTransition in={fixed} timeout={200} classNames="fixed">
        <div
          className={cx(
            css.fixedWrapper,
            fixed && css.fixed,
            mobileNavOpen && css.active,
          )}
        >
          {renderHeader(refHeader)}
        </div>
      </CSSTransition>

      <MobileNavModal open={mobileNavOpen}>
        <div className={css.mobileNavWrapper}>
          {renderHeader()}
          <SearchField />
          <MobileNav />
          <MobileSocials />
        </div>
      </MobileNavModal>
    </>
  )
}

export default Header
